import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { Fragment, lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import { AppInsightsContextProvider } from './AppInsightsContext';
import Cookies from './modules/base/components/Cookies/Bar';
import CookieTerms from './modules/base/components/Cookies/Terms';
import Dashboard from './modules/base/components/Dashboard';
import PrivateRoute from './modules/base/components/PrivateRoute';
import { config } from './modules/base/models/Config';
import Redeem from './modules/userManagement/views/Redeem';
import BreadCrumbs from './sharedComponents/BreadCrumbs';
import Loader from './sharedComponents/Loader';

const Device = lazy(() => import('./modules/device/views'));
const TermsOfUse = lazy(() => import('./modules/base/components/TermsOfUse'));
const UserManagement = lazy(() => import('./modules/userManagement/views'));
const ForgotPassword = lazy(() => import('./modules/userManagement/views/ForgotPassword'));
const Footer = lazy(() => import('./modules/base/components/Footer'));
const NavBar = lazy(() => import('./modules/base/components/NavBar'));
const Warranty = React.lazy(() => import('./modules/warranty/views'));
const Parts = lazy(() => import('./modules/parts/views'));
const SignIn = lazy(() => import('./modules/userManagement/views/SignIn'));
const UserSettings = lazy(() => import('./modules/userManagement/views/UserSettings'));
const Service = lazy(() => import('./modules/service/views'));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      flex: '1 0 auto',
      maxWidth: 1800,
      margin: '20px auto',
      [theme.breakpoints.down('sm')]: {
        margin: '25px auto 50px auto'
      }
    },
    wrapper: {
      padding: '0 20px'
    },
    padding: {
      [theme.breakpoints.up('md')]: {
        padding: '0 10%'
      },
      [theme.breakpoints.up('lg')]: {
        padding: '0 200px'
      }
    },
    breadCrumb: {
      marginBottom: '10px'
    }
  })
);

const MainApp = () => {
  const classes = useStyles();
  const userRoles = ['representative', 'authorizedUser', 'admin', 'systemUser'];
  const allAccessLevel = ['PartsOrders', 'PartsQuotation', 'PartsInvoices', 'ReturnOrders', 'PartsBackOrders', 'PartInquiry', 'PartsTransactions',
  'DeviceBackOrders', 'DeviceOrders', 'DeviceQuotation', 'DeviceInquiries', 'DeviceInvoices', 'DeliveryReports',
  'DeviceStatus', 'AllDevices', 'DealerDevices','DeviceSalesAllocation', 'DeviceSalesCapacity','DeviceCase', 'DealerDevices', 'WarrantyClaims' , 
  'ServiceCampaigns' , 'Case', 'RegisterMaintenance', 'All', 'PartsDocuments','DeviceDocuments','ServiceDocuments', 'ServiceContracts', 
  'WarrantyCase', 'WarrantyDocuments', 'PartsCase', 'ServiceClaims'];
  return (
    <Suspense fallback={<Loader />}>
      <Fragment>
        {!config.hideWrapper && <NavBar className={classes.padding} />}
        <div className={classes.wrapper} dir='ltr'>
          <Suspense fallback={<Loader />}>
            <div className={`${classes.main}`}>
              {!config.hideWrapper && (
                <div className={classes.breadCrumb}>
                  <BreadCrumbs />
                </div>
              )}
              <PrivateRoute exact path='/' component={(props: any) => <Dashboard {...props} />} />
              <PrivateRoute
                path='/parts'
                roleAccess={userRoles}
                accessLevel={['PartsOrders', 'PartsQuotation',  'PartsInvoices', 'ReturnOrders', 'PartsBackOrders', 'PartInquiry', 'PartsTransaction', 'All', 'PartsDocuments','PartsCase']}
                component={(props: any) => <Parts {...props} />}
              />
              <PrivateRoute 
                path='/admin'
                roleAccess={['admin']}
                accessLevel={allAccessLevel}
                component={(props: any) => <UserManagement {...props} />}
              />
              <PrivateRoute
                path='/device'
                roleAccess={userRoles}
                accessLevel={['DeviceBackOrders', 'DeviceOrders', 'DeviceQuotation', 'DeviceInquiries',
                 'DeviceInvoices', 'DeliveryReports', 'DeviceStatus', 'AllDevices', 'DealerDevices', 'DealerDevices','All', 'DeviceDocuments', 'DeviceSalesAllocation','DeviceSalesCapacity','DeviceCase']}
                component={(props: any) => <Device {...props} />}
              />
              <PrivateRoute
                path='/warranty'
                roleAccess={userRoles}
                accessLevel={['WarrantyClaims','ServiceCampaigns', 'WarrantyCase','WarrantyDocuments', 'All']}
                component={(props: any) => <Warranty {...props} />}
              />
              <PrivateRoute path='/user' component={(props: any) => <UserSettings {...props} />} />
              <PrivateRoute
                path='/service'
                roleAccess={userRoles}
                accessLevel={['Case', 'RegisterMaintenance', 'All', 'ServiceDocuments','ServiceContracts','ServiceClaims']}
                component={(props: any) => <Service {...props} />}
              />
              <Route path='/privacy-policy' component={() => <TermsOfUse />} />
              <Route path='/cookie-policy' component={() => <CookieTerms />} />
            </div>
          </Suspense>
        </div>
        {!config.hideWrapper && <Footer />}
      </Fragment>
    </Suspense>
  );
};

export default () => {
  return (
    <AppInsightsContextProvider>
      <Fragment>
        <Switch>
          <Route path='/sign-in' component={() => <SignIn />} />
          <Route path='/forgot-password' component={() => <ForgotPassword />} />
          <Route path='/redeem/:token' component={(props: any) => <Redeem {...props} />} />
          <Route path='/' component={MainApp} />
        </Switch>
        <Cookies />
      </Fragment>
    </AppInsightsContextProvider>
  );
};
