import { ISite } from '../../../helpers/typeHelpers';
import { user } from '../../userManagement/models/User';


const warrantySiteMap: ISite[] = [];
if (user.hasAccessLevel(['WarrantyClaims', 'All'])) {
  warrantySiteMap.push({
    label: 'Warranty Claims',
    path: '/warranty/claims',
    children: [],
    showInMenu: true
  },
    {
      label: 'Create Claim',
      path: '/warranty/create-claim',
      children: [],
      showInMenu: false
    });
}
if (user.hasAccessLevel(['ServiceCampaigns', 'All'])) {
  warrantySiteMap.push({
    label: 'Service Campaigns',
    path: '/warranty/campaigns',
    children: [],
    showInMenu: true
  },
    {
      label: 'Service Campaign Description',
      path: '/warranty/campaign/:id',
      children: [],
      showInMenu: false
    }
  );
}
if (user.hasAccessLevel(['WarrantyCase', 'All'])) {
  warrantySiteMap.push({
    label: 'Case',
    location: 'Warranty', 
    path: '/warranty/cases',
    children: [],
    showInMenu: true
  },
    {
      label: 'Create Case',
      path: '/warranty/create-case',
      children: [],
      showInMenu: false
    },
    {
      label: 'Case Description',
      path: '/warranty/cases/:id',
      children: [],
      showInMenu: false
    });
}
if (user.hasAccessLevel(['WarrantyDocuments', 'All'])) {
  warrantySiteMap.push({
    label: 'Documents',
    path: '/warranty/documents',
    children: [],
    showInMenu: true
  });
}

export default warrantySiteMap;

