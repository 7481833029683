import { isValidEmail } from "../../../../helpers/helperFunctions";

export default (self: any) => ({

  get emailError() {
    if (self.claimEmail) {
     return isValidEmail(self.claimEmail);
    }
    return false;
  }

});