import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { config } from '../modules/base/models/Config';
import { user } from '../modules/userManagement/models/User';
import { IOrderingParams, IQueryParam } from './typeHelpers';

export const arr2obj = (arr: any[], key: string): {[key: string]: any} => {
  const obj: any = {};

  for (const item of arr) {
    obj[item[key]] = item;
  }

  return obj;
};

export const uuidv4 = () => 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
  const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
  return v.toString(16);
});

export const createLink = (path?: string) => (
  React.forwardRef<HTMLAnchorElement, Omit<LinkProps, 'innerRef' | 'to'>>(
    (props: any, ref) => (path ? <Link innerRef={ref as any} to={path} {...props} /> : null)
  )
);

export const getPath = () => {
  if (config.useHashRouting) {
    return window.location.hash.slice(1);
  } else {
    return window.location.pathname;
  }
};

export const clamp = (num: number, min: number, max: number) => num <= min ? min : num >= max ? max : num;

// tslint:disable-next-line: max-line-length
export const phoneValidationRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export const generateQueryString = (params: IQueryParam, orderParams?: IOrderingParams): string => {
  const queries: string[] = [];

  for (const key in params) {
    if (
      params.hasOwnProperty(key) &&
      typeof params[key] !== 'undefined' &&
      params[key] !== null &&
      params[key] !== '' &&
      !(Number(params[key]) < 1 && key === 'pageNumber')
    ) {
      queries.push(`${key}=${encodeURIComponent(params[key] || '')}`);
    }
  }

  if (orderParams?.orderBy) {
    const tmpOrderParams = orderParams.orderBy.split(' ');
    queries.push(`orderBy=${tmpOrderParams[0]} ${orderParams.ordering || tmpOrderParams[1] || 'asc'}`);
  }

  return queries.length > 0 ? `?${queries.join('&')}` : '';
};

export const dateOrNull = (date?: string | null) => {
  return date && date !== '0001-01-01T00:00:00' ? new Date(date) : null;
};

export const dateOrNullOrInvalid = (date?: string | null) => {
  try
  {return date && date !== '0001-01-01T00:00:00' ? (new Date(date).toISOString() ? new Date(date) : null)  : null;}
  catch(err)
  {
    return null;
  }  
};

export const dateFormat = (date?: Date | null) => {
  if (date && user.dateFormat === 'MM / dd / yyyy') { 
  return new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(new Date(Number(date)))
  }
  if (date && user.dateFormat === 'dd / MM / yyyy') { 
    return new Intl.DateTimeFormat('en-GB', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(new Date(Number(date)))
  }
  return null;
};

export const isValidURL = (value: string) => {
  const regex = new RegExp('^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$');
  return !regex.test(value);
};

export const isValidEmail = (email: string) => {
  const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return !regexp.test(email);
}

export const openInNewTab = (url: string): void => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
};

export const onClickUrl = (url: string): (() => void) => () => openInNewTab(url);