import { Instance, types } from 'mobx-state-tree';

const AccessLevel = types.model('AccessLevel', {
  code: types.string,
  name: types.string
});

export const allAccessLevel: IAccessLevel[] = [
  AccessLevel.create({ name: 'All', code: 'All' }),
  AccessLevel.create({ name: 'All Vehicles', code: 'AllDevices' }),
  AccessLevel.create({ name: 'Dealer Vehicles', code: 'DealerDevices' }),
  AccessLevel.create({ name: 'Delivery Reports', code: 'DeliveryReports' }),
  AccessLevel.create({ name: 'Parts Back Orders', code: 'PartsBackOrders' }),
  AccessLevel.create({ name: 'Parts Case', code: 'PartsCase' }),
  AccessLevel.create({ name: 'Parts Documents', code: 'PartsDocuments' }),
  AccessLevel.create({ name: 'Parts Inquiry', code: 'PartInquiry' }),
  AccessLevel.create({ name: 'Parts Invoices', code: 'PartsInvoices' }),
  AccessLevel.create({ name: 'Parts Orders', code: 'PartsOrders' }),
  AccessLevel.create({ name: 'Parts Quotation', code: 'PartsQuotation' }),
  AccessLevel.create({ name: 'Parts Return Orders', code: 'ReturnOrders' }),
  AccessLevel.create({ name: 'Parts Transaction', code: 'PartsTransactions' }),
  AccessLevel.create({ name: 'Register Schedule Service', code: 'RegisterMaintenance' }),
  AccessLevel.create({ name: 'Service Campaigns', code: 'ServiceCampaigns' }),
  AccessLevel.create({ name: 'Service Case', code: 'Case' }),
  AccessLevel.create({ name: 'Service Claims', code: 'ServiceClaims' }),
  AccessLevel.create({ name: 'Service Contracts', code: 'ServiceContracts' }),
  AccessLevel.create({ name: 'Service Documents', code: 'ServiceDocuments' }),
  AccessLevel.create({ name: 'Vehicle Back Orders', code: 'DeviceBackOrders' }),
  AccessLevel.create({ name: 'Vehicle Case', code: 'DeviceCase' }),
  AccessLevel.create({ name: 'Vehicle Documents', code: 'DeviceDocuments' }),
  AccessLevel.create({ name: 'Vehicle Inquiries', code: 'DeviceInquiries' }),
  AccessLevel.create({ name: 'Vehicle Invoices', code: 'DeviceInvoices' }),
  AccessLevel.create({ name: 'Vehicle Orders', code: 'DeviceOrders' }),
  AccessLevel.create({ name: 'Vehicle Quotation', code: 'DeviceQuotation' }),
  AccessLevel.create({ name: 'Vehicle Sales Allocation', code: 'DeviceSalesAllocation' }),
  AccessLevel.create({ name: 'Vehicle Sales Capacity', code: 'DeviceSalesCapacity' }),
  AccessLevel.create({ name: 'Vehicle Status', code: 'DeviceStatus' }),
  AccessLevel.create({ name: 'Warranty Case', code: 'WarrantyCase' }),
  AccessLevel.create({ name: 'Warranty Claims', code: 'WarrantyClaims' }),
  AccessLevel.create({ name: 'Warranty Documents', code: 'WarrantyDocuments' }),
];

export default AccessLevel;
export interface IAccessLevel extends Instance<typeof AccessLevel> {}
