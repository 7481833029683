import { ISite } from '../../../helpers/typeHelpers';
import { user } from '../../userManagement/models/User';


const partsSiteMap: ISite[] = [];
if (user.hasAccessLevel(['PartsOrders', 'All'])) {
  partsSiteMap.push({
      label: 'Parts Orders',
      path: '/parts/orders',
      children: [],
      showInMenu: true
    },
    {
      label: 'Order',
      path: '/parts/orders/id',
      children: [],
      showInMenu: false
    },
    {
      label: 'Create Order',
      path: '/parts/create-order',
      children: [],
      showInMenu: false
    });
  }
  if (user.hasAccessLevel(['PartsQuotation', 'All'])) {
    partsSiteMap.push({
      label: 'Parts Quotation',
      path: '/parts/quotation',
      children: [],
      showInMenu: true
    },
      {
        label: 'Quotation',
        path: '/parts/quotation/id',
        children: [],
        showInMenu: false
      },
      {
        label: 'Create Quotation',
        path: '/parts/create-quotation',
        children: [],
        showInMenu: false
      });
  }
if (user.hasAccessLevel(['PartsInvoices', 'All'])) {
  partsSiteMap.push({
      label: 'Invoice',
      path: '/parts/invoices/id',
      children: [],
      showInMenu: false
    },
    {
      label: 'Parts Invoices',
      path: '/parts/invoices',
      children: [],
      showInMenu: true
    });
  }
if (user.hasAccessLevel(['ReturnOrders', 'All'])) {
    partsSiteMap.push({
      label: 'Return orders',
      path: '/parts/return-orders-core',
      children: [],
      showInMenu: true
    });
  }
  if (user.hasAccessLevel(['ReturnOrders', 'All'])) {
    partsSiteMap.push({
      label: 'Return orders claims',
      path: '/parts/return-orders-invoice',
      children: [],
      showInMenu: true
    });
  }
if (user.hasAccessLevel(['PartsBackOrders', 'All'])) {
  partsSiteMap.push({
      label: 'Back Orders',
      path: '/parts/back-orders',
      children: [],
      showInMenu: true
    });
  }
if (user.hasAccessLevel(['PartInquiry', 'All'])) {
    partsSiteMap.push({
        label: 'Part Inquiries',
        path: '/parts/part-inquiry',
        children: [],
        showInMenu: true
      });
}
if (user.hasAccessLevel(['PartsTransactions', 'All'])) {
  partsSiteMap.push({
    label: 'Parts Transactions',
    path: '/parts/parts-transactions',
    children: [],
    showInMenu: true
  });
}
  
if (user.hasAccessLevel(['PartsCase', 'All'])) {
  partsSiteMap.push({
      label: 'Case',
      location: 'Parts',
      path: '/parts/cases',
      children: [],
      showInMenu: true
    },
    {
      label: 'Create Case',
      path: '/parts/create-case',
      children: [],
      showInMenu: false
    },
    {
      label: 'Case Description',
      path: '/parts/cases/:id',
      children: [],
      showInMenu: false
    });
  }
  if (user.hasAccessLevel(['PartsDocuments', 'All'])) {
    partsSiteMap.push({
      label: 'Documents',
      path: '/parts/documents',
      children: [],
      showInMenu: true
    });
  }
export default partsSiteMap;