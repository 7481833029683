import { Instance, onSnapshot, types } from 'mobx-state-tree';

import Actions from './Actions';
import Model from './Model';
import Views from './Views';

const DealerConfiguration = types
.model('DealerConfiguration', Model)
.actions(Actions)
.views(Views);

export default DealerConfiguration;

let initialState = {};

if (localStorage.getItem('dealerConfiguration')) {
  const json = JSON.parse(localStorage.getItem('dealerConfiguration') || '');
  if (DealerConfiguration.is(json)) {
    initialState = {...json, ...initialState};
  }
}

export const dealerConfiguration = DealerConfiguration.create(initialState) as IDealerConfiguration;

onSnapshot(dealerConfiguration as any, snapshot => {
  localStorage.setItem('dealerConfiguration', JSON.stringify(snapshot));
});

export interface IDealerConfiguration extends Instance<typeof DealerConfiguration> {
  }

export interface IConfigurationWarrantyClaimRequest {
  webClaimNumLine: boolean;
  claimNumLine: boolean;
  claimNameLine: boolean;
  deviceNumberLine: boolean;
  claimTypeLine: boolean;
  createdDateLine: boolean;
  createdByLine: boolean;
  claimStatusLine: boolean;
  approvalStatusLine: boolean;
  totalClaimedAmountLine: boolean;
  totalApprovedAmountLine: boolean;
  warrantyClaimListPreWorkApproval: boolean;
  warrantyClaimEmailNotification: boolean;
  warrantyClaimDownloadStatement: boolean,
  warrantyClaimDownloadInvoice: boolean, 
  
  deviceBrand: boolean;
  deviceClass: boolean;
  deviceModel: boolean;
  deviceModelCode: boolean;
  deviceServiceModel: boolean;
  deviceNumber: boolean;
  deviceRegistrationNumber: boolean;
  deviceSerialNumber: boolean;
  deviceVINNumber: boolean;
  deviceUsage: boolean;
  
  claimName: boolean;
  claimGroup: boolean;
  causalPart: boolean;
  serviceCampaignNumber: boolean;
  returnReasonCode: boolean;
  claimSerialNumber: boolean;
  claimSummaryContractCode: boolean;
  claimContractCode: boolean;
  externalReferenceNumber: boolean;
  serviceStartDate: boolean;
  serviceEndDate: boolean;
  contactName: boolean;
  contactEmail: boolean;
  contactPhoneNumber: boolean;
  damageSymptomCode: boolean;
  damageCauseCode: boolean;
  damageResolutionCode: boolean;
  damageCodeA: boolean;
  damageCodeB: boolean;
  damageCodeC: boolean;
  detailsSymptoms: boolean;
  detailsCause: boolean;
  detailsSolutions: boolean;
  detailsNotes: boolean;
  detailsPreWorkApproval: boolean,
  operationNumber: boolean;
  laborDescription: boolean;
  laborReference: boolean;
  laborStandardHours: boolean;
  laborActualHours: boolean;
  laborUnitPrice: boolean;
  laborTotalPrice: boolean;
  laborMainOprId: boolean;
  partNumber: boolean;
  partsDescription: boolean;
  partsReference: boolean;
  partsQty: boolean;
  partsUnit: boolean;
  partsUnitPrice: boolean;
  partsTotalPrice: boolean;
  partsLineCausalPart: boolean;
  partsPartsImage: boolean;
  returnablePart: boolean;
  miscCodes: boolean;
  miscCodesDescription: boolean;
  miscDealerReference: boolean;
  miscChargesQty: boolean;
  miscUnitPrice: boolean;
  miscTotalPrice: boolean;
  currency: boolean;
  claimNum: boolean;
  claimStatus: boolean;
  approvalStatus: boolean;
  claimSummaryClaimId: boolean;
  claimSummaryDeviceNum: boolean;
  claimSummaryVINNum: boolean;
  claimSummaryRegistrationNum: boolean;
  claimSummarySerialNum: boolean;
  claimSummaryClaimType: boolean;
  claimSummaryClaimGroup: boolean;
  claimSummaryUsage: boolean;
  laborApprovedQty: boolean;
  partsApprovedQty: boolean;
  miscApprovedQty: boolean;
  claimSubmittedEmailNotification: boolean;
  claimEmail: string,
  claimEmailName: string,
  claimSummaryDmgSymptomCode: boolean,
  claimSummaryDmgCauseCode: boolean,
  claimSummaryDmgResolutionCode: boolean,
  claimSummaryDmgFailureCodeA: boolean,
  claimSummaryDmgFailureCodeB: boolean,
  claimSummaryDmgFailureCodeC: boolean,
  claimSummaryDetailsSymptoms: boolean,
  claimSummaryDetailsCause: boolean,
  claimSummaryDetailsSolutions: boolean,
  claimSummaryDetailsNptes: boolean,
  claimCurrentUsageButton: boolean,
}

export interface IConfigurationPartsOrderRequest {
  createOrderButton: boolean,
  webOrderIdList: boolean;
  salesOrderNumberList: boolean;
  dealerReferenceNumberList: boolean;
  orderDateList: boolean;
  createdByList: boolean;
  orderTypeList: boolean;
  statusList: boolean;
  totalAmountList: boolean;
  dealerRequisition: boolean;
  dealerReferenceNumber: boolean;
  partsOrderPhoneNumber: boolean;
  partsOrderModeOfDelivery: boolean;
  partsOrderDeilveryName: boolean;
  partsOrderAddress: boolean;
  partsOrderRequestedShipDate: boolean;
  partsOrderRequestedDeliveryDate: boolean;
  partNumberLine: boolean;
  partNameLine: boolean;
  deviceReferenceLine: boolean;
  dealerReferenceLine: boolean;
  quantityLine: boolean;
  unitLine: boolean;
  unitPriceLine: boolean;
  discountLine: boolean;
  discountPercentageLine: boolean;
  totalAmountLine: boolean;
  partsOrderCurrency: boolean;
  partsLineSite: boolean;
  partsLineWarehouse: boolean;
  partsLineOnHand: boolean;
  partsLineConfig: boolean;
  partsLineSize: boolean;
  partsLineColor: boolean;
  partsLineStyle: boolean;
  partsLineConfirmedReceiptDate: boolean;
  partsLineConfirmedShipDate: boolean;
  partsLinePartsImage: boolean;
  partsLineAltItemPartsImage: boolean;
  partsLineRepItemPartsImage: boolean;
  partsLineSuppItemPartsImage: boolean;
  partsOrderContactEmail: boolean;
  partsOrderContactName: boolean;
  salesOrderNumber: boolean,
  podDocStatus: boolean,
  status: boolean,
  orderDate: boolean,
  partsLineDeliverRemainder: boolean,
  partsLineProductCatalog: boolean,
  partsLineImportLines: boolean,
  partsLineAddAttachments: boolean,
  noSupersession: boolean
}

export interface IConfigurationPartsTransactionRequest {
  ptListPartNumber: boolean,
  ptListPartName: boolean,
  ptListPhyiscalDate: boolean,
  ptListWarehouse: boolean,
  ptListReference: boolean,
  ptListSalesOrderNum: boolean,
  ptListInvoiceNum: boolean,
  ptListOrderLineQuantity: boolean,
  ptListQty: boolean,
  ptListLineStatus: boolean,
  ptListStatus: boolean,
  ptListDeviceReference: boolean,
  ptListConfirmedShipDate: boolean,
}

export interface IConfigurationPartsTransactionRequest {
  ptListPartNumber: boolean,
  ptListPartName: boolean,
  ptListPhyiscalDate: boolean,
  ptListWarehouse: boolean,
  ptListReference: boolean,
  ptListSalesOrderNum: boolean,
  ptListInvoiceNum: boolean,
  ptListQty: boolean,
  ptListStatus: boolean,
  ptListDeviceReference: boolean,
  ptListConfirmedShipDate: boolean,
}

export interface IConfigurationDeviceOrderRequest {
  deviceOrderListCreateOrderButton: boolean,
  deviceOrderListWebOrderId: boolean;
  deviceOrderListSalesOrderNum: boolean;
  deviceOrderListDealerRefNum: boolean;
  deviceOrderListDate: boolean;
  deviceOrderListCreatedBy: boolean;
  deviceOrderListType: boolean;
  deviceOrderListStatus: boolean;
  deviceOrderListTotalAmount: boolean;
  deviceOrderListAllocationMonth: boolean,
  deviceOrderListAllocationYear: boolean,
  dodDealerReference: boolean;
  dodDealerRequisition: boolean;
  dodInvoiceAccount: boolean;
  dodRequestInvoice: boolean;
  dodContactName: boolean;
  dodContactEmail: boolean;
  dodContactPhoneNumber: boolean;
  dodSalesOrderNum: boolean,
  dodStatus: boolean,
  dodDocStatus: boolean,
  dodDate: boolean,
  dodDeilveryName: boolean,
  dodAddress: boolean,
  dodRequestedShipDate: boolean,
  dodRequestedDeliveryDate: boolean,
  dodProductionDate: boolean,
  dolItemNumber: boolean;
  dolDeviceName: boolean;
  dolDeviceNumber: boolean;
  dolConfiguration: boolean;
  dolExterior: boolean;
  dolInterior: boolean;
  dolStyle: boolean;
  dolQuantity: boolean;
  dolUnit: boolean;
  dolConfirmedReceiptDate: boolean;
  dolConfirmedShipDate: boolean;
  dolUnitPrice: boolean,
  dolLineStatus: boolean,
  dolDiscount: boolean,
  dolDiscountPct: boolean,
  dolTotalPrice: boolean,
  docItemCode: boolean;
  docConfiguration: boolean;
  docExterior: boolean;
  docInterior: boolean;
  docStyle: boolean;
  docCampaign: boolean;
  docOrdered: boolean;
  docInShipping: boolean;
  docInStock: boolean;
  docConsignment: boolean;
  docRentalFleet: boolean;
  dosCurrency: boolean;
  dolDeliverRemainder: boolean;
  dolAccessoriesPartsImage: boolean;
}

export interface IConfigurationDeviceInquiryRequest {
  diListName: boolean,
  diListBrand: boolean,
  diListClass: boolean,
  diListModel: boolean,
  diListModelCode: boolean,
  diListModelYear: boolean,
  diListDeviceNum: boolean,
  diListSerialNum: boolean,
  diListRegNum: boolean,
  diListVINNum: boolean,
  diListStatus: boolean,
  diGeneralDeviceNumber: boolean,
  diGeneralDeviceName: boolean,
  diGeneralMasterNum: boolean,
  diGeneralRegNum: boolean,
  diGeneralPrivateNum: boolean,
  diGeneralMajorStatus: boolean,
  diSetupBrandNum: boolean,
  diSetupClassNum: boolean,
  diSetupModelNum: boolean,
  diSetupModelCodeNum: boolean,
  diSetupModelConfig: boolean,
  diSetupInteriorNum: boolean,
  diSetupExteriorNum: boolean,
  diSetupModelYear: boolean,
  diSetupProductionDate: boolean,
  diSetupCountryOrigin: boolean,
  diSetupEngineDisplacement: boolean,
  diSetupEngineHP: boolean,
  diSetupEngineType: boolean,
  diSetupBodyStyle: boolean,
  diSetupFacelift: boolean,
  diSetupFuelType: boolean,
  diSetupNumCylinder: boolean,
  diCustodianName: boolean,
  diCustodianOwner: boolean,
  diCustodianFirstOwner: boolean,
  diCustodianOperator: boolean,
  diCustodianCoOwner: boolean,
  diCustodianActive: boolean,
  diServiceCampaignLinesNum: boolean,
  diServiceCampaignLinesDescription: boolean,
  diServiceCampaignLinesStartDate: boolean,
  diServiceCampaignLinesStatus: boolean,
  diServiceCampaignLinesEndDate: boolean,
  diDeviceAccessoriesSpecificationType: boolean,
  diDeviceAccessoriesItemNum: boolean,
  diDeviceAccessoriesDescription: boolean,
  diDeviceAccessoriesComponentNum: boolean,
  diDeviceAccessoriesCategory: boolean,
  diDeviceAccessoriesQuantity: boolean,
  diDeviceAccessoriesStocked: boolean,
  diDeviceAccessoriesReplaced: boolean,
  diDeviceAccessoriesStatus: boolean,
  diDeviceAccessoriesTransactionDate: boolean,
  diDeviceAccessoriesStatusDate: boolean,
  diDeviceChildSpecificationType: boolean,
  diDeviceChildItemNum: boolean,
  diDeviceChildDescription: boolean,
  diDeviceChildComponentNum: boolean,
  diDeviceChildCategory: boolean,
  diDeviceChildQuantity: boolean,
  diDeviceChildStocked: boolean,
  diDeviceChildReplaced: boolean,
  diDeviceChildStatus: boolean,
  diDeviceChildTransactionDate: boolean,
  diDeviceChildStatusDate: boolean,
  diDeviceComponentsSpecificationType: boolean,
  diDeviceComponentsItemNum: boolean,
  diDeviceComponentsDescription: boolean,
  diDeviceComponentsNum: boolean,
  diDeviceComponentsCategory: boolean,
  diDeviceComponentsQuantity: boolean,
  diDeviceComponentsStocked: boolean,
  diDeviceComponentsReplaced: boolean,
  diDeviceComponentsStatus: boolean,
  diDeviceComponentsTranscationDate: boolean,
  diDeviceComponentsStatusDate: boolean,
  diOptionsSpecificationType: boolean,
  diOptionsItemNum: boolean,
  diOptionsDescription: boolean,
  diOptionsComponentNum: boolean,
  diOptionsCategory: boolean,
  diOptionsQuantity: boolean,
  diOptionsStocked: boolean,
  diOptionsReplaced: boolean,
  diOptionsStatus: boolean,
  diOptionsTransactionDate: boolean,
  diOptionsStatusDate: boolean,
  diDevicePropertiesDeviceName: boolean,
  diDevicePropertiesDeviceNum: boolean,
  diDevicePropertiesPropertyGroup: boolean,
  diDevicePropertiesPropertyGroupCode: boolean,
  diDevicePropertiesNotes: boolean,
  diWarrantyType: boolean,
  diWarrantyDescription: boolean,
  diWarrantStartDate: boolean,
  diWarrantyEndDate: boolean,
  diWarrantyStartUsage: boolean,
  diWarrantyEndUsage: boolean,
  diWorkOrderHistNum: boolean,
  diWorkOrderHistDescription: boolean,
  diWorkOrderHistCustomerAccount: boolean,
  diWorkOrderHistStatus: boolean,
  diWorkOrderHistWorkOrderGrp: boolean,
  diWorkOrderHistType: boolean,
  diWorkOrderHistStartDate: boolean,
  diWorkOrderHistCreatedDate: boolean,
  diWorkOrderHistEstimatedHours: boolean,
  diWorkOrderHistHoursRemaining: boolean,
  diClaimHistWarrantyClaim: boolean,
  diClaimHistStatus: boolean,
  diClaimHistApprovalStatus: boolean,
  diClaimHistInvoiceStatus: boolean,
  diClaimHistWarrantyClaimGrp: boolean,
  diClaimHistType: boolean,
  diClaimHistDevice: boolean,
  diClaimHistPreWorkApproval: boolean,
  diClaimHistUsage: boolean,
  diServicePlanNum: boolean,
  diServicePlanName: boolean,
  diServicePlanStatus: boolean,
  diServicePlanActivation: boolean,
  diServicePlanReferenceDate: boolean,
  diServicePlanContractNum: boolean,
  diServicePlanGrp: boolean,
  diScheduledServiceDueDate: boolean,
  diScheduledServiceUsage: boolean,
  diScheduledServiceUsageUnit: boolean,
  diScheduledServiceUsageDueDate: boolean,
  diScheduledServiceName: boolean,
  diScheduledServiceServicePlanGrp: boolean,
  diScheduledServiceStatus: boolean,
  diScheduledServiceStopped: boolean,
  diScheduledServiceWorkOrderNum: boolean,
  diScheduledServiceWorkOrderStatus: boolean,
  diScheduledServiceClosedDate: boolean,
  diDocumentsName: boolean,
  diDocumentsType: boolean,
  diDocumentsNotes: boolean,
  diServiceContractContractId: boolean,
  diServiceContractDeviceId: boolean,
  diServiceContractDeviceGroupId: boolean,
  diServiceContractInvoiceContractId: boolean,
  diServiceContractContractType: boolean,
  diServiceContractCollectionStatus: boolean,
  diServiceContractCustAccount: boolean,
  diServiceContractName: boolean
}

export interface IConfigurationDeliveryReportRequest {
  drListWebId: boolean,
  drListCreatedBy: boolean,
  drListCreatedDate: boolean,
  drListStatus: boolean,
  drInfoHeaderWebId: boolean,
  drInfoHeaderReportNum: boolean,
  drInfoHeaderCreatedBy: boolean,
  drInfoHeaderCreatedDate: boolean,
  drInfoHeaderStatus: boolean,
  drInfoLinesDeviceInfoVinNum: boolean,
  drInfoLinesDeviceInfoDeviceNum: boolean,
  drInfoLinesDeviceInfoSerialNum: boolean,
  drInfoLinesGeneralUsage: boolean,
  drInfoLinesGeneralUsageGrp: boolean,
  drInfoLinesGeneralMjrStatus: boolean,
  drInfoLinesGeneralFinalSalesDate: boolean,
  drInfoLinesGeneralRegDate: boolean,
  drInfoLinesGeneralRefDate: boolean,
  drInfoLinesGeneralWarrantyType: boolean,
  drInfoLinesParentDeviceInfoVinNum: boolean,
  drInfoLinesParentDeviceInfoParentDeviceNum: boolean,
  drInfoLinesParentDeviceInfoSerialNum: boolean,
  drInfoLinesParentDeviceInfoUpdateParentDeviceCustodian: boolean,
  drInfoLinesCustodianName: boolean,
  drInfoLinesCustodianEmail: boolean,
  drInfoLinesCustodianPhoneNum: boolean,
  drInfoLinesCustodianAddress: boolean,
  drCreateLineVinNum: boolean,
  drCreateLineDeviceNum: boolean,
  drCreateLineSerialNum: boolean,
  drCreateGeneralUsage: boolean,
  drCreateGeneralUsageGrp: boolean,
  drCreateGeneralMjrStatus: boolean,
  drCreateGeneralFinalSalesDate: boolean,
  drCreateGeneralRegDate: boolean,
  drCreateGeneralRefDate: boolean,
  drCreateGeneralWarrantyType: boolean,
  drCreateGeneralRegNum: boolean,
  drCreateParentDeviceVinNum: boolean,
  drCreateParentDeviceNum: boolean,
  drCreateParentDeviceSerialNum: boolean,
  drCreateParentDeviceUpdateParentDeviceCustodian: boolean,
  drCreateCustodianName: boolean,
  drCreateCustodianEmail: boolean,
  drCreateCustodianPhoneNum: boolean,
  drCreateCustodianAddress: boolean,
}

export interface IConfigurationAllDevicesRequest {
  daListDevicNumber: boolean,
  daListDeviceName: boolean,
  daListItemNumber: boolean,
  daListModelCodeNumber: boolean,
  daListConfigName: boolean,
  daListModelYear: boolean,
  daListRegistrationNumber: boolean,
  daListChassislNum: boolean,
  daListMajorStatus: boolean,
  daListSalesPrice: boolean,
  daListCampaignPrice: boolean,
  daGeneralDeviceNumber: boolean,
  daGeneralDeviceName: boolean,
  daGeneralMasterNum: boolean,
  daGeneralRegNum: boolean,
  daGeneralPrivateNum: boolean,
  daGeneralMajorStatus: boolean,
  daSetupBrandNum: boolean,
  daSetupClassNum: boolean,
  daSetupModelNum: boolean,
  daSetupModelCodeNum: boolean,
  daSetupModelConfig: boolean,
  daSetupInteriorNum: boolean,
  daSetupExteriorNum: boolean,
  daSetupModelYear: boolean,
  daSetupProductionDate: boolean,
  daSetupCountryOrigin: boolean,
  daSetupEngineDisplacement: boolean,
  daSetupEngineHP: boolean,
  daSetupEngineType: boolean,
  daSetupBodyStyle: boolean,
  daSetupFacelift: boolean,
  daSetupFuelType: boolean,
  daSetupNumCylinder: boolean,
  daCustodianName: boolean,
  daCustodianOwner: boolean,
  daCustodianFirstOwner: boolean,
  daCustodianOperator: boolean,
  daCustodianCoOwner: boolean,
  daCustodianActive: boolean,
  daServiceCampaignLinesNum: boolean,
  daServiceCampaignLinesDescription: boolean,
  daServiceCampaignLinesStartDate: boolean,
  daServiceCampaignLinesStatus: boolean,
  daServiceCampaignLinesEndDate: boolean,
  daDeviceAccessoriesSpecificationType: boolean,
  daDeviceAccessoriesItemNum: boolean,
  daDeviceAccessoriesDescription: boolean,
  daDeviceAccessoriesComponentNum: boolean,
  daDeviceAccessoriesCategory: boolean,
  daDeviceAccessoriesQuantity: boolean,
  daDeviceAccessoriesStocked: boolean,
  daDeviceAccessoriesReplaced: boolean,
  daDeviceAccessoriesStatus: boolean,
  daDeviceAccessoriesTransactionDate: boolean,
  daDeviceAccessoriesStatusDate: boolean,
  daDeviceChildSpecificationType: boolean,
  daDeviceChildItemNum: boolean,
  daDeviceChildDescription: boolean,
  daDeviceChildComponentNum: boolean,
  daDeviceChildCategory: boolean,
  daDeviceChildQuantity: boolean,
  daDeviceChildStocked: boolean,
  daDeviceChildReplaced: boolean,
  daDeviceChildStatus: boolean,
  daDeviceChildTransactionDate: boolean,
  daDeviceChildStatusDate: boolean,
  daDeviceComponentsSpecificationType: boolean,
  daDeviceComponentsItemNum: boolean,
  daDeviceComponentsDescription: boolean,
  daDeviceComponentsNum: boolean,
  daDeviceComponentsCategory: boolean,
  daDeviceComponentsQuantity: boolean,
  daDeviceComponentsStocked: boolean,
  daDeviceComponentsReplaced: boolean,
  daDeviceComponentsStatus: boolean,
  daDeviceComponentsTranscationDate: boolean,
  daDeviceComponentsStatusDate: boolean,
  daOptionsSpecificationType: boolean,
  daOptionsItemNum: boolean,
  daOptionsDescription: boolean,
  daOptionsComponentNum: boolean,
  daOptionsCategory: boolean,
  daOptionsQuantity: boolean,
  daOptionsStocked: boolean,
  daOptionsReplaced: boolean,
  daOptionsStatus: boolean,
  daOptionsTransactionDate: boolean,
  daOptionsStatusDate: boolean,
  daDevicePropertiesDeviceName: boolean,
  daDevicePropertiesDeviceNum: boolean,
  daDevicePropertiesPropertyGroup: boolean,
  daDevicePropertiesPropertyGroupCode: boolean,
  daDevicePropertiesNotes: boolean,
  daWarrantyType: boolean,
  daWarrantyDescription: boolean,
  daWarrantStartDate: boolean,
  daWarrantyEndDate: boolean,
  daWarrantyStartUsage: boolean,
  daWarrantyEndUsage: boolean,
  daWorkOrderHistNum: boolean,
  daWorkOrderHistDescription: boolean,
  daWorkOrderHistCustomerAccount: boolean,
  daWorkOrderHistStatus: boolean,
  daWorkOrderHistWorkOrderGrp: boolean,
  daWorkOrderHistType: boolean,
  daWorkOrderHistStartDate: boolean,
  daWorkOrderHistCreatedDate: boolean,
  daWorkOrderHistEstimatedHours: boolean,
  daWorkOrderHistHoursRemaining: boolean,
  daClaimHistWarrantyClaim: boolean,
  daClaimHistStatus: boolean,
  daClaimHistApprovalStatus: boolean,
  daClaimHistInvoiceStatus: boolean,
  daClaimHistWarrantyClaimGrp: boolean,
  daClaimHistType: boolean,
  daClaimHistDevice: boolean,
  daClaimHistPreWorkApproval: boolean,
  daClaimHistUsage: boolean,
  daServicePlanNum: boolean,
  daServicePlanName: boolean,
  daServicePlanStatus: boolean,
  daServicePlanActivation: boolean,
  daServicePlanReferenceDate: boolean,
  daServicePlanContractNum: boolean,
  daServicePlanGrp: boolean,
  daScheduledServiceDueDate: boolean,
  daScheduledServiceUsage: boolean,
  daScheduledServiceUsageUnit: boolean,
  daScheduledServiceUsageDueDate: boolean,
  daScheduledServiceName: boolean,
  daScheduledServiceServicePlanGrp: boolean,
  daScheduledServiceStatus: boolean,
  daScheduledServiceStopped: boolean,
  daScheduledServiceWorkOrderNum: boolean,
  daScheduledServiceWorkOrderStatus: boolean,
  daScheduledServiceClosedDate: boolean,
  daDocumentsName: boolean,
  daDocumentsType: boolean,
  daDocumentsNotes: boolean,
  daServiceContractContractId: boolean,
  daServiceContractDeviceId: boolean,
  daServiceContractDeviceGroupId: boolean,
  daServiceContractInvoiceContractId: boolean,
  daServiceContractContractType: boolean,
  daServiceContractCollectionStatus: boolean,
  daServiceContractCustAccount: boolean,
  daServiceContractName: boolean
}

export interface IConfigurationDealerDevicesRequest {
  ddListDevicNumber: boolean,
  ddListDeviceName: boolean,
  ddListItemNumber: boolean,
  ddListModelCodeNumber: boolean,
  ddListConfigName: boolean,
  ddListModelYear: boolean,
  ddListRegistrationNumber: boolean,
  ddListChassislNum: boolean,
  ddListMajorStatus: boolean,
  ddListSalesPrice: boolean,
  ddListCampaignPrice: boolean,
  ddGeneralDeviceNumber: boolean,
  ddGeneralDeviceName: boolean,
  ddGeneralMasterNum: boolean,
  ddGeneralRegNum: boolean,
  ddGeneralPrivateNum: boolean,
  ddGeneralMajorStatus: boolean,
  ddSetupBrandNum: boolean,
  ddSetupClassNum: boolean,
  ddSetupModelNum: boolean,
  ddSetupModelCodeNum: boolean,
  ddSetupModelConfig: boolean,
  ddSetupInteriorNum: boolean,
  ddSetupExteriorNum: boolean,
  ddSetupModelYear: boolean,
  ddSetupProductionDate: boolean,
  ddSetupCountryOrigin: boolean,
  ddSetupEngineDisplacement: boolean,
  ddSetupEngineHP: boolean,
  ddSetupEngineType: boolean,
  ddSetupBodyStyle: boolean,
  ddSetupFacelift: boolean,
  ddSetupFuelType: boolean,
  ddSetupNumCylinder: boolean,
  ddCustodianName: boolean,
  ddCustodianOwner: boolean,
  ddCustodianFirstOwner: boolean,
  ddCustodianOperator: boolean,
  ddCustodianCoOwner: boolean,
  ddCustodianActive: boolean,
  ddServiceCampaignLinesNum: boolean,
  ddServiceCampaignLinesDescription: boolean,
  ddServiceCampaignLinesStartDate: boolean,
  ddServiceCampaignLinesStatus: boolean,
  ddServiceCampaignLinesEndDate: boolean,
  ddDeviceAccessoriesSpecificationType: boolean,
  ddDeviceAccessoriesItemNum: boolean,
  ddDeviceAccessoriesDescription: boolean,
  ddDeviceAccessoriesComponentNum: boolean,
  ddDeviceAccessoriesCategory: boolean,
  ddDeviceAccessoriesQuantity: boolean,
  ddDeviceAccessoriesStocked: boolean,
  ddDeviceAccessoriesReplaced: boolean,
  ddDeviceAccessoriesStatus: boolean,
  ddDeviceAccessoriesTransactionDate: boolean,
  ddDeviceAccessoriesStatusDate: boolean,
  ddDeviceChildSpecificationType: boolean,
  ddDeviceChildItemNum: boolean,
  ddDeviceChildDescription: boolean,
  ddDeviceChildComponentNum: boolean,
  ddDeviceChildCategory: boolean,
  ddDeviceChildQuantity: boolean,
  ddDeviceChildStocked: boolean,
  ddDeviceChildReplaced: boolean,
  ddDeviceChildStatus: boolean,
  ddDeviceChildTransactionDate: boolean,
  ddDeviceChildStatusDate: boolean,
  ddDeviceComponentsSpecificationType: boolean,
  ddDeviceComponentsItemNum: boolean,
  ddDeviceComponentsDescription: boolean,
  ddDeviceComponentsNum: boolean,
  ddDeviceComponentsCategory: boolean,
  ddDeviceComponentsQuantity: boolean,
  ddDeviceComponentsStocked: boolean,
  ddDeviceComponentsReplaced: boolean,
  ddDeviceComponentsStatus: boolean,
  ddDeviceComponentsTranscationDate: boolean,
  ddDeviceComponentsStatusDate: boolean,
  ddOptionsSpecificationType: boolean,
  ddOptionsItemNum: boolean,
  ddOptionsDescription: boolean,
  ddOptionsComponentNum: boolean,
  ddOptionsCategory: boolean,
  ddOptionsQuantity: boolean,
  ddOptionsStocked: boolean,
  ddOptionsReplaced: boolean,
  ddOptionsStatus: boolean,
  ddOptionsTransactionDate: boolean,
  ddOptionsStatusDate: boolean,
  ddDevicePropertiesDeviceName: boolean,
  ddDevicePropertiesDeviceNum: boolean,
  ddDevicePropertiesPropertyGroup: boolean,
  ddDevicePropertiesPropertyGroupCode: boolean,
  ddDevicePropertiesNotes: boolean,
  ddWarrantyType: boolean,
  ddWarrantyDescription: boolean,
  ddWarrantStartDate: boolean,
  ddWarrantyEndDate: boolean,
  ddWarrantyStartUsage: boolean,
  ddWarrantyEndUsage: boolean,
  ddWorkOrderHistNum: boolean,
  ddWorkOrderHistDescription: boolean,
  ddWorkOrderHistCustomerAccount: boolean,
  ddWorkOrderHistStatus: boolean,
  ddWorkOrderHistWorkOrderGrp: boolean,
  ddWorkOrderHistType: boolean,
  ddWorkOrderHistStartDate: boolean,
  ddWorkOrderHistCreatedDate: boolean,
  ddWorkOrderHistEstimatedHours: boolean,
  ddWorkOrderHistHoursRemaining: boolean,
  ddClaimHistWarrantyClaim: boolean,
  ddClaimHistStatus: boolean,
  ddClaimHistApprovalStatus: boolean,
  ddClaimHistInvoiceStatus: boolean,
  ddClaimHistWarrantyClaimGrp: boolean,
  ddClaimHistType: boolean,
  ddClaimHistDevice: boolean,
  ddClaimHistPreWorkApproval: boolean,
  ddClaimHistUsage: boolean,
  ddServicePlanNum: boolean,
  ddServicePlanName: boolean,
  ddServicePlanStatus: boolean,
  ddServicePlanActivation: boolean,
  ddServicePlanReferenceDate: boolean,
  ddServicePlanContractNum: boolean,
  ddServicePlanGrp: boolean,
  ddScheduledServiceDueDate: boolean,
  ddScheduledServiceUsage: boolean,
  ddScheduledServiceUsageUnit: boolean,
  ddScheduledServiceUsageDueDate: boolean,
  ddScheduledServiceName: boolean,
  ddScheduledServiceServicePlanGrp: boolean,
  ddScheduledServiceStatus: boolean,
  ddScheduledServiceStopped: boolean,
  ddScheduledServiceWorkOrderNum: boolean,
  ddScheduledServiceWorkOrderStatus: boolean,
  ddScheduledServiceClosedDate: boolean,
  ddDocumentsName: boolean,
  ddDocumentsType: boolean,
  ddDocumentsNotes: boolean,
  ddServiceContractContractId: boolean,
  ddServiceContractDeviceId: boolean,
  ddServiceContractDeviceGroupId: boolean,
  ddServiceContractInvoiceContractId: boolean,
  ddServiceContractContractType: boolean,
  ddServiceContractCollectionStatus: boolean,
  ddServiceContractCustAccount: boolean,
  ddServiceContractName: boolean
}

export interface IConfigurationPartInquiryRequest {
  piReleasedGeneralItemNumber: boolean,
  piReleasedGeneralProductName: boolean,
  piReleasedGeneralUnit: boolean,
  piReleasedGeneralListPrice: boolean,
  piReleasedGeneralPartsImage: boolean,
  piReleasedSetupNetWeight: boolean,
  piReleasedSetupTareWeight: boolean,
  piReleasedSetupGrossWeight: boolean,
  piReleasedSetupGrossDepth: boolean,
  piReleasedSetupGrossWidth: boolean,
  piReleasedSetupGrossHeight: boolean,
  piReleasedSetupHazardousMaterial: boolean,
  piReleasedSetupBlocked: boolean,
  piReleasedSetupReasonCode: boolean,
  piReleasedSetupBrand: boolean,
  piReleasedSetupClass: boolean,
  piReleasedSetupModel: boolean,
  piReleasedItemChangeHeaderItemChange: boolean,
  piReleasedItemChangeHeaderItemNumber: boolean,
  piReleasedItemChangeHeaderName: boolean,
  piReleasedItemChangeHeaderProductName: boolean,
  piReleasedItemChangeHeaderDescription: boolean,
  piReleasedItemChangeHeaderStatus: boolean,
  piReleasedItemChangeLineItemQty: boolean,
  piReleasedItemChangeLineItemNumber: boolean,
  piReleasedItemChangeLineName: boolean,
  piReleasedItemChangeLineUnit: boolean,
  piReleasedItemChangeLineUniDirectional: boolean,
  piReleasedOnHandQtyItemNumber: boolean,
  piReleasedOnHandQtyName: boolean,
  piReleasedOnHandQtyConfiguration: boolean,
  piReleasedOnHandQtySize: boolean,
  piReleasedOnHandQtyColor: boolean,
  piReleasedOnHandQtyStyle: boolean,
  piReleasedOnHandQtySerialNumber: boolean,
  piReleasedOnHandQtySite: boolean,
  piReleasedOnHandQtyWareHouse: boolean,
  piReleasedOnHandQtyLocation: boolean,
  piReleasedOnHandQtyLicensePlate: boolean,
  piReleasedOnHandQtyQty: boolean,
  piReleasedAlternativeItemsNumber: boolean,
  piReleasedAlternativeItemsProductName: boolean,
  piReleasedAlternativeItemsAlternativeConfig: boolean,
  piReleasedAlternativeItemsSize: boolean,
  piReleasedAlternativeItemsColor: boolean,
  piReleasedAlternativeItemsStyle: boolean,
  piReleasedSuppItemsSuppItem: boolean,
  piReleasedSuppItemsProductName: boolean,
  piReleasedSuppItemsConfig: boolean,
  piReleasedSuppItemsSize: boolean,
  piReleasedSuppItemsColor: boolean,
  piReleasedSuppItemsStyle: boolean,
  piReleasedSuppItemsQty: boolean,
  piReleasedSuppItemsUnit: boolean,
  piReleasedSuppItemsFreeOfCharge: boolean,
  piReleasedSuppItemsItemType: boolean,
  piReleasedSuppItemsOnlyOnce: boolean,
  piReleasedBomBom: boolean,
  piReleasedBomName: boolean,
  piReleasedBomItemNumber: boolean,
  piReleasedBomProductName: boolean,
  piReleasedBomSubBom: boolean,
  piReleasedBomConfig: boolean,
  piReleasedBomSize: boolean,
  piReleasedBomColor: boolean,
  piReleasedBomStyle: boolean,
  piReleasedBomQty: boolean,
  piReleasedBomFromDate: boolean,
  piReleasedBomToDate: boolean,
  piReleasedSalesPriceCurrency: boolean,
  piReleasedSalesPriceAccountSelection: boolean,
  piReleasedSalesPriceConfig: boolean,
  piReleasedSalesPriceSize: boolean,
  piReleasedSalesPriceColor: boolean,
  piReleasedSalesPriceStyle: boolean,
  piReleasedSalesPriceFromDate: boolean,
  piReleasedSalesPriceToDate: boolean,
  piReleasedSalesPriceFrom: boolean,
  piReleasedSalesPriceTo: boolean,
  piReleasedSalesPricePrice: boolean,
  piReleasedLineDiscountCurrency: boolean,
  piReleasedLineDiscountAccountSelection: boolean,
  piReleasedLineDiscountPartRelation: boolean,
  piReleasedLineDiscountConfig: boolean,
  piReleasedLineDiscountSize: boolean,
  piReleasedLineDiscountColor: boolean,
  piReleasedLineDiscountStyle: boolean,
  piReleasedLineDiscountFromDate: boolean,
  piReleasedLineDiscountToDate: boolean,
  piReleasedLineDiscountFrom: boolean,
  piReleasedLineDiscountTo: boolean,
  piReleasedLineDiscountAmountInTransactionCurrency: boolean,
  piReleasedLineDiscountDiscountPct1: boolean,
  piReleasedLineDiscountDiscountPct2: boolean,
  piSupplierGeneralBrand: boolean,
  piSupplierGeneralDiscontinued: boolean,
  piSupplierGeneralBlockedPrdctCreation: boolean,
  piSupplierGeneralHeight: boolean,
  piSupplierGeneralNetWeight: boolean,
  piSupplierGeneralSuppItemNum: boolean,
  piSupplierGeneralRefurbished: boolean,
  piSupplierGeneralSalesPrice: boolean,
  piSupplierGeneralWidth: boolean,
  piSupplierGeneralSuppItemName: boolean,
  piSupplierGeneralReturnItem: boolean,
  piSupplierGeneralDepth: boolean,
  piSupplierGeneralPartsImage: boolean,
  piSupplierItemChangeHeaderItemChange: boolean,
  piSupplierItemChangeHeaderSuppItemNum: boolean,
  piSupplierItemChangeHeaderName: boolean,
  piSupplierItemChangeHeaderBrandNum: boolean,
  piSupplierItemChangeHeaderDescription: boolean,
  piSupplierItemChangeHeaderStatus: boolean,
  piSupplierItemChangeLineBrandNumber: boolean,
  piSupplierItemChangeLineSuppItemNum: boolean,
  piSupplierItemChangeLineName: boolean,
  piSupplierItemChangeLineQty: boolean,
  piSupplierItemChangeLineUnit: boolean,
  piSupplierItemChangeLineUniDirectional: boolean,
  piSupplierSuppItemsBrandNum: boolean,
  piSupplierSuppitemsSuppItemNum: boolean,
  piSupplierSuppItemsReturnItemBehavior: boolean,
  piSupplierSuppItemsNum: boolean,
  piSupplierSuppItemsItemName: boolean,
  piReleasedSupplierItemBrandNum: boolean,
  piReleasedSupplierItemSuppItemNum: boolean,
  piReleasedSupplierItemSuppWarehouse: boolean,
  piReleasedSupplierItemQty: boolean,
  piReleasedSupplierItemOnHandDate: boolean,
  piSupplierItemOnHandBrandNum: boolean,
  piSupplierItemOnHandSuppItemNum: boolean,
  piSupplierItemOnHandSuppWarehouse: boolean,
  piSupplierItemOnHandQty: boolean,
  piSupplierItemOnHandOnHandDate: boolean,
  piGeneralEvoBusQty: boolean

}

export interface IConfigurationServiceCampaignRequest {
  scListNum: boolean,
  scListManufacturerServiceCampaign: boolean,
  scListDescription: boolean,
  scListBrand: boolean,
  scListStartDate: boolean,
  scListEndDate: boolean,
  scListStatus: boolean,
  scListCampaignType: boolean,
  scInfoGeneralNum: boolean,
  scInfoGeneralDescription: boolean,
  scInfoGeneralExtendedEndDate: boolean,
  scInfoGeneralNotes: boolean,
  scInfoGeneralStartDate: boolean,
  scInfoGeneralStatus: boolean,
  scInfoGeneralBrandNum: boolean,
  scInfoGeneralEndDate: boolean,
  scInfoDamageSymptomCode: boolean,
  scInfoDamageFailureCodeA: boolean,
  scInfoDamageCauseCOde: boolean,
  scInfoDamageFailureCodeB: boolean,
  scInfoDamageResolutionCode: boolean,
  scInfoDamageFailureCodeC: boolean,
  scInfoLinesDeviceNum: boolean,
  scInfoLinesChassisNum: boolean,
  scInfoLinesName: boolean,
  scInfoLinesServiceCampaignStatus: boolean,
  scInfoLinesServiceCampaignCloseDate: boolean,
  scInfoDocumentsName: boolean,
  scInfoDocumentsType: boolean,
  scInfoDocumentsNotes: boolean,
  scInfoJobListId: boolean,
  scInfoJobListDescription: boolean,
  scInfoJobListSymptomCode: boolean,
  scInfoJobListCauseCode: boolean,
  scInfoJobListResolutionCode: boolean,
  scInfoJobListLaborsOperationNumber: boolean,
  scInfoJobListLaborsDescription: boolean,
  scInfoJobListLaborsStandardHours: boolean,
  scInfoJobListLaborsMultiply: boolean,
  scInfoJobListLaborsFixedHours: boolean,
  scInfoJobListLaborsMainOperation: boolean,
  scInfoJobListLaborsStart: boolean,
  scInfoJobListLaborsFinish: boolean,
  scInfoJobListPartsNumber: boolean,
  scInfoJobListPartsDescription: boolean,
  scInfoJobListPartsQuantity: boolean,
  scInfoJobListPartsUnit: boolean,
  scInfoJobListPartsUnitPrice: boolean,
  scInfoJobListPartsDiscount: boolean,
  scInfoJobListPartsDiscountPercentage: boolean,
  scInfoJobListPartsTotalPrice: boolean,
}

export interface IConfigurationPartInvoicesRequest {
  piListInvoiceNum: boolean,
  piListSalesOrderNum: boolean,
  piListInvoiceDate: boolean,
  piListInvoiceAmount: boolean,
  piListCurrency: boolean,
  piDetailsGeneralInvoiceNum: boolean,
  piDetailsGeneralInvoiceDate: boolean,
  piDetailsGeneralCurrency: boolean,
  piDetailsSalesOrderNum: boolean,
  piDetailsShipToAddress: boolean,
  piDetailsLinesPartNum: boolean,
  piDetailsLinesPartName: boolean,
  piDetailsLinesDeviceRef: boolean,
  piDetailsLinesStatus: boolean,
  piDetailsLinesQty: boolean,
  piDetailsLinesUnitPrice: boolean,
  piDetailsLinesDiscount: boolean,
  piDetailsLinesDiscountPct: boolean,
  piDetailsLinesTotalPrice: boolean,
  piDetailsLinesSize: boolean,
  piDetailsLinesColor: boolean,
  piDetailsLinesConfig: boolean,
  piDetailsLinesStyle: boolean,
}

export interface IConfigurationDeviceInvoicesRequest {
  diListInvoiceNum: boolean,
  diListSalesOrderNum: boolean,
  diListInvoiceDate: boolean,
  diListInvoiceAmount: boolean,
  diListCurrency: boolean,
  diListDueDate: boolean,
  diDetailsGeneralInvoiceNum: boolean,
  diDetailsGeneralInvoiceDate: boolean,
  diDetailsGeneralCurrency: boolean,
  diDetailsGeneralDueDate: boolean,
  diDetailsSalesOrderNum: boolean,
  diDetailsShipToAddress: boolean,
  diDetailsYourOrderDeviceConfigNum: boolean,
  diDetailsYourOrderDeviceConfigName: boolean,
  diDetailsYourOrderDeviceNum: boolean,
  diDetailsYourOrderQty: boolean,
  diDetailsYourOrderUnit: boolean,
  diDetailsYourOrderUnitPrice: boolean,
  diDetailsYourOrderDiscount: boolean,
  diDetailsYourOrderDisccountPct: boolean,
  diDetailsYourOrderTotalAmount: boolean,
}

export interface IConfigurationDeviceBackOrderRequest {
  dboListReqShipDate: boolean,
  dboListConfirmedShipDate: boolean,
  dboListSalesOrder: boolean,
  dboListItemNum: boolean,
  dboListDescription: boolean,
  dboListDeviceNum: boolean,
  dboListQty: boolean,
  dboListDeliveryReminder: boolean,
  dboListUnit: boolean,
  dboListStatus: boolean,
  dboListPurchaseOrderNumber: boolean,
  deviceBackOrderDeleteOption: boolean;
}

export interface IConfigurationPartBackOrderRequest {
  pboListReqShipDate: boolean,
  pboListConfirmedShipDate: boolean,
  pboListSalesOrder: boolean,
  pboListItemNum: boolean,
  pboListDescription: boolean,
  pboListDeviceNum: boolean,
  pboListQty: boolean,
  pboListDeliveryReminder: boolean,
  pboListUnit: boolean,
  pboListStatus: boolean,
  pboListPurchaseOrderNumber: true,
  partsBackOrderDeleteOption: boolean;
}

export interface IConfigurationPartReturnOrderRequest {
  proListWebOrderId: boolean,
  proListReturnOrderNum: boolean,
  proListRMANum: boolean,
  proListReturnReasonCode: boolean,
  proListCreatedBy: boolean,
  proListCreatedDate: boolean,
  proListStatus: boolean,
  proSummaryGeneralRMANum: boolean,
  proSummaryGeneralReturnReasonCode: boolean,
  proSummaryGeneralDealerRefNum: boolean,
  proSummaryGeneralRequisition: boolean,
  proSummaryGeneralReturnOrderNum: boolean,
  proSummaryLinesPartNum: boolean,
  proSummaryLinesPartName: boolean,
  proSummaryLinesDeviceRef: boolean,
  proSummaryLinesStatus: boolean,
  proSummaryLinesQty: boolean,
  proSummaryLinesUnitPrice: boolean,
  proSummaryLinesDiscount: boolean,
  proSummaryLinesDiscountPct: boolean,
  proSummaryLinesTotalPrice: boolean,
  proSummaryLinesSize: boolean,
  proSummaryLinesColor: boolean,
  proSummaryLinesConfig: boolean,
  proSummaryLinesStyle: boolean,
}

export interface IConfigurationCaseRequest {
  cListWebCaseId: boolean,
  cListCaseId: boolean,
  cListDescription: boolean,
  cListCreatedBy: boolean,
  cListCreadtedDate: boolean,
  cListStatus: boolean,
  ciGeneralCategory: boolean,
  ciGeneralStatus: boolean,
  ciGeneralCreatedDate: boolean,
  ciGeneralPriority: boolean,
  ciGeneralNotes: boolean,
  ciGeneralDescription: boolean,
  ciGeneralDeviceId: boolean,
  ciGeneralCreatedBy: boolean,
  ciGeneralDepartment: boolean,
  ciGeneralCaseResolution: boolean,
  ciGeneralCaseId: boolean,
  ciGeneralCaseProcess: boolean,
  ciGeneralParentCase: boolean,
  ciCaseLogCreatedDate: boolean,
  ciCaseLogDescription: boolean,
  ciCaseLogCreatedBy: boolean,
  ciCaseLogNotes: boolean,
  ccGeneralCategoryType: boolean,
  ccGeneralCaseCategory: boolean,
  ccGeneralParentCase: boolean,
  ccGeneralPriority: boolean,
  ccGeneralDescription: boolean,
  ccContactName: boolean,
  ccContactEmail: boolean,
  ccContactPhoneNumber: boolean,
  ccNotes: boolean,
  ccCaseLogDescription: boolean,
  ccCaseLogNotes: boolean,
  ccAttachments: boolean,
}

export interface IConfigurationPartsQuotationRequest {
  pqListWebQuotationId: boolean,
  pqListQuotationNumber: boolean,
  pqListDealerReferenceNumber: boolean,
  pqListOrderDate: boolean,
  pqListSalesOrderNumber: boolean,
  pqListCreatedBy: boolean,
  pqListOrderType: boolean,
  pqListStatus: boolean,
  pqListTotalAmount: boolean,
  pqDetailsDealerRequisition: boolean,
  pqDetailsDealerReferenceNumber: boolean,
  pqDetailsPartsQuotationContactEmail: boolean,
  pqDetailsPartsQuotationContactName: boolean,
  pqDetailsPartsQuotationPhoneNumber: boolean,
  pqDetailsQuotationNumber: boolean,
  pqDetailsStatus: boolean,
  pqDetailsQuotationDate: boolean,
  pqLinesPartNumber: boolean,
  pqLinesPartName: boolean,
  pqLinesDeviceReference: boolean,
  pqLinesDealerReference: boolean,
  pqLinesQuantity: boolean,
  pqLinesSite: boolean,
  pqLinesWarehouse: boolean,
  pqLinesOnHand: boolean,
  pqLinesConfig: boolean,
  pqLinesSize: boolean,
  pqLinesColor: boolean,
  pqLinesStyle: boolean,
  pqLinesUnit: boolean,
  pqLinesUnitPrice: boolean,
  pqLinesDiscount: boolean,
  pqLinesDiscountPercentage: boolean,
  pqLinesTotalAmount: boolean,
  pqLinesPartsQuotationCurrency: boolean,
  pqLinesProductCatalog: boolean,
  pqLinesImportLines: boolean,
  pqLinesAddAttachments: boolean,
  pqLinesConfirmedShipDate: boolean,
  pqLinesConfirmedReceiptDate: boolean,
  pqLinesDeliverReminder: boolean,
  pqCreateQuotation: boolean
}

export interface IConfigurationDeviceQuotationRequest {
  dqListWebQuotationId: boolean,
  dqListQuotationNum: boolean,
  dqListSalesOrderNum: boolean,
  dqListDealerRefNum: boolean,
  dqListDate: boolean,
  dqListCreatedBy: boolean,
  dqListStatus: boolean,
  dqListTotalAmount: boolean,
  dqCreateQuotation: boolean,
  dqConfigurationCurrency: boolean,
  dqDetailsDealerReference: boolean,
  dqDetailsDealerRequisition: boolean,
  dqDetailsInvoiceAccount: boolean,
  dqDetailsRequestInvoice: boolean,
  dqDetailsContactName: boolean,
  dqDetailsContactEmail: boolean,
  dqDetailsContactPhoneNumber: boolean,
  dqDetailsSalesOrderNum: boolean,
  dqDetailsStatus: boolean,
  dqDetailsDate: boolean,
  dqLinesItemNumber: boolean,
  dqLinesDeviceName: boolean,
  dqLinesDeviceNumber: boolean,
  dqLinesConfiguration: boolean,
  dqLinesExterior: boolean,
  dqLinesInterior: boolean,
  dqLinesStyle: boolean,
  dqLinesQuantity: boolean,
  dqLinesUnit: boolean,
  dqLinesConfirmedShipDate: boolean,
  dqLinesConfirmedReceiptDate: boolean,
  dqLinesDeliverRemainder: boolean,
  dqConfigurationItemCode: boolean,
  dqConfigurationConfiguration: boolean,
  dqConfigurationExterior: boolean,
  dqConfigurationInterior: boolean,
  dqConfigurationStyle: boolean,
  dqConfigurationCampaign: boolean,
  dqConfigurationOrdered: boolean,
  dqConfigurationInShipping: boolean,
  dqConfigurationInStock: boolean,
  dqConfigurationConsignment: boolean,
  dqConfigurationRentalFleet: boolean
}
