import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { flatSiteMap } from '../config/sitemap';
import { createLink, getPath } from '../helpers/helperFunctions';
import { ISite } from '../helpers/typeHelpers';
import Link from './Link';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  link: {
    color: 'inherit',
    textDecoration: 'none'
  }
}));

export interface ISiteMap {
  path: string;
  label: string;
  location : string;
}

const Breadcrumb = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const locations = getPath().replace(/^\//, '').split('/');

  const breadCrums: ISite[] = [];
  let currentBreadCrumb;

  locations.map((_location: string, index: number) => {
    currentBreadCrumb = '/' + locations.slice(0, index + 1).join('/');
    const site = flatSiteMap.find((element: ISite) => {
      return element.path === currentBreadCrumb;
    });
    if (site) {
      breadCrums.push(site);
    }
  });
  if (locations.length === breadCrums.length + 1) {
    const siteUrl = '/' + locations.slice(0, breadCrums.length).join('/') + '/id';
    const site = flatSiteMap.find((element: ISite) => {
      return element.path === siteUrl;
    });
    if (site) {
      breadCrums.push(site);
    }
  }
  return (
    <div className={classes.root}>
      <Breadcrumbs aria-label='Breadcrumb'>
        {breadCrums.map((site, index)  => {
          switch (index) {
            case breadCrums.length - 1:
              return (
                <Typography key={index} color='textPrimary'>
                  {(typeof site.label === 'string' && site.label === 'Service') ? 'Service Contracts' 
                  : 
                  site.label === 'Case' ? site.label + ' - ' + site.location : site.label}
                </Typography>
              );
            default:
              return (
                <Link key={index} component={createLink(site.path)} className={classes.link}>
                  {typeof site.label === 'string' ? t(site.label) : site.label}
                </Link>
              );
          }
        })}
      </Breadcrumbs>
    </div>
  );
};

export default (Breadcrumb);
