import { types } from 'mobx-state-tree';
import AccessLevel from '../AccessLevel';
import DateFormat from '../DateFormat';

import Dealer from '../Dealer';
import DealerConfiguration from '../DealerConfiguration';
import Role from '../Role';

export default {
  id: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  userName: types.maybeNull(types.string),
  b2cUserName: types.maybeNull(types.string),
  b2cAuthenticated: false,
  name: types.maybeNull(types.string),
  phoneNumber: types.maybeNull(types.string),
  userEmail: types.maybeNull(types.string),
  disabled: false,
  admin: false,
  dealer: types.maybeNull(types.reference(Dealer)),
  dealers: types.array(Dealer),
  reloading: false,
  dealerRoleId: types.maybeNull(types.string),
  dealerRoleName: types.maybeNull(types.string),
  roles: types.array(Role),
  accessLevels: types.array(AccessLevel),
  dateFormat: types.maybeNull(types.string),

  inviteToken: types.maybeNull(types.string),
  resetPasswordToken: types.maybeNull(types.string),
  authenticating: false,
  errorMessage: types.maybeNull(types.string),
  authToken: types.maybeNull(types.string),
  displaySignInDialog: false,
  displayDealerDialog: false,
  displaySignOutDialog: false,
  lockSignInDialog: false,
  dealerError: types.maybeNull(types.string),
  membershipError: types.maybeNull(types.string),
  resettingPassword: false,
  resetPasswordEmail: false,

  lastLoggedIn: types.maybeNull(types.Date),
  updating: false,

  createdDate: types.maybeNull(types.Date),
  createdBy: types.maybeNull(types.string),
  updatedDate: types.maybeNull(types.Date),
  updatedBy: types.maybeNull(types.string),
  userLoginB2C: false,
  displayB2CSignInDialog: false,
  displayB2CDealerDialog: false,
  lockB2CSignInDialog: false,
  portalMaintenanceMessage: types.maybeNull(types.string),
  portalMaintenanceURL: types.maybeNull(types.string),
  masterAdmin: false,
  roleAdmin: false,
  fieldConfigurationsTab: 0,
  deviceModuleFieldConfigurationsTab: 0,
  partModuleFieldConfigurationTab: 0,
  serviceModuleFieldConfigurationTab: 0,
  syncStatusConfigurationTab: 0,
  isAuthTokenException: false,
  isValidate: false,
  adAccessToken: types.maybeNull(types.string),
  localAccountId: types.maybeNull(types.string)
};
