import { ISite } from '../../../helpers/typeHelpers';
import { user } from '../../userManagement/models/User';

const deviceSiteMap: ISite[] = [];
if (user.hasAccessLevel(['DeviceOrders', 'All'])) {
    deviceSiteMap.push({
      label: 'Vehicle Orders',
      path: '/device/orders',
      children: [],
      showInMenu: true
    });
  }
if (user.hasAccessLevel(['DeviceQuotation', 'All'])) {
    deviceSiteMap.push({
      label: 'Vehicle Quotation',
      path: '/device/quotations',
      children: [],
      showInMenu: true
    });
}  
if (user.hasAccessLevel(['DeviceInquiries', 'All'])) {
    deviceSiteMap.push({
      label: 'Vehicle Inquiries',
      path: '/device/inquiries',
      children: [],
      showInMenu: true
    },
    {
      label: 'Vehicle Inquiry',
      path: '/device/inquiry/id',
      children: [],
      showInMenu: false
    });
  }
if (user.hasAccessLevel(['DeviceInvoices', 'All'])) {
    deviceSiteMap.push({
      label: 'Vehicle Invoices',
      path: '/device/invoices',
      children: [],
      showInMenu: true
    });
  }
if (user.hasAccessLevel(['DeliveryReports', 'All'])) {
    deviceSiteMap.push({
      label: 'Delivery Reports',
      path: '/device/delivery-reports',
      children: [],
      showInMenu: true
    },
    {
      label: 'Create Delivery Report',
      path: '/device/create-delivery-report',
      children: [],
      showInMenu: false
    },
    {
      label: 'Delivery report',
      path: '/device/delivery-report',
      children: [],
      showInMenu: false
    },
    {
      label: 'Report',
      path: '/device/delivery-report/id',
      children: [],
      showInMenu: false
    }
    );
  }
if (user.hasAccessLevel(['DeviceBackOrders', 'All'])) {
    deviceSiteMap.push({
      label: 'Back Orders',
      path: '/device/back-orders',
      children: [],
      showInMenu: true
    });
  }
if (user.hasAccessLevel(['DeviceStatus', 'All'])) {
    deviceSiteMap.push({
      label: 'Vehicle Order Status',
      path: '/device/device-order-status',
      children: [],
      showInMenu: true
    });
  }
if (user.hasAccessLevel(['DealerDevices', 'All'])) {
  deviceSiteMap.push({
    label: 'Dealer Vehicles',
    path: '/device/dealer-devices',
    children: [],
    showInMenu: true
  },
    {
      label: 'Dealer Vehicles Details',
      path: '/device/dealer-device/id',
      children: [],
      showInMenu: false
    });
}
if (user.hasAccessLevel(['AllDevices', 'All'])) {
    deviceSiteMap.push({
      label: 'All Vehicles',
      path: '/device/all-devices',
      children: [],
      showInMenu: true
    },
    {
      label: 'All Vehicles Details',
      path: '/device/all-device/id',
      children: [],
      showInMenu: false
    });
  }
  if (user.hasAccessLevel(['DeviceSalesAllocation', 'All'])) {
    deviceSiteMap.push({
      label: 'Vehicle Sales Allocation',
      path: '/device/sales-allocation',
      children: [],
      showInMenu: true
    });
  }
  if (user.hasAccessLevel(['DeviceSalesCapacity', 'All'])) {
    deviceSiteMap.push({
      label: 'Vehicle Sales Capacity',
      path: '/device/sales-capacity',
      children: [],
      showInMenu: true
    });
  }
  if (user.hasAccessLevel(['DeviceCase', 'All'])) {
    deviceSiteMap.push({
      label: 'Case',
      location: 'Vehicle',
      path: '/device/cases',
      children: [],
      showInMenu: true
    });
  }
  if (user.hasAccessLevel(['DeviceDocuments', 'All'])) {
    deviceSiteMap.push({
      label: 'Documents',
      path: '/device/documents',
      children: [],
      showInMenu: true
    });
  }

export default deviceSiteMap;
