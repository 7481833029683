import { ISite } from '../../../helpers/typeHelpers';
import { user } from '../../userManagement/models/User';

const serviceSiteMap: ISite[] = [];
    if (user.hasAccessLevel(['ServiceContracts', 'All'])) {
      serviceSiteMap.push({
        label: 'Service Contracts',
        path: '/service/contracts',
        children: [],
        showInMenu: true
      });
    }
    if (user.hasAccessLevel(['ServiceClaims', 'All'])) {
      serviceSiteMap.push({
        label: 'Service Claims',
        path: '/service/serviceclaims',
        children: [],
        showInMenu: true
      },
        {
          label: 'Create Claim',
          path: '/service/create-claim',
          children: [],
          showInMenu: false
        });
    }
    if (user.hasAccessLevel(['RegisterMaintenance', 'All'])) {
      serviceSiteMap.push({
        label: 'Register Schedule Service',
        path: '/service/register-maintenance',
        children: [],
        showInMenu: true
      },
        {
          label: 'Update schedule service',
          path: '/service/register-maintenance/update-service-plan/id',
          children: [],
          showInMenu: false
        });
    }
    if (user.hasAccessLevel(['Case', 'All'])) {
      serviceSiteMap.push({
          label: 'Case',
          location: 'Service',
          path: '/service/cases',
          children: [],
          showInMenu: true
        },
        {
          label: 'Create Case',
          path: '/service/create-case',
          children: [],
          showInMenu: false
        });
      }
      if (user.hasAccessLevel(['ServiceDocuments', 'All'])) {
        serviceSiteMap.push({
          label: 'Documents',
          path: '/service/documents',
          children: [],
          showInMenu: true
        });
      }
export default serviceSiteMap;
